import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet as Head } from 'react-helmet-async';

interface Props {
  title?: string;
  description?: string;
  keywords?: string[];
  image?: string;
}
export const Meta = (props: Props) => {
  const {
    site: {
      siteMetadata: { url },
    },
  } = useStaticQuery(graphql`
    query HeadQuery {
      site {
        siteMetadata {
          url
        }
      }
    }
  `);
  return (
    <Head>
      {props.title && <title>{props.title}</title>}
      {props.title && <meta property="og:title" content={props.title} />}
      {props.description && (
        <meta name="description" content={props.description} />
      )}
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      {props.keywords?.length > 0 && (
        <meta name="keywords" content={props.keywords.join(',')} />
      )}
      {props.image && <meta property="og:image" content={url + props.image} />}
    </Head>
  );
};

export default Meta;
