import React from 'react';
import styled from '@emotion/styled';

import { fadeDownInCss } from 'style/snippets';

const Panel = styled.div`
  height: var(--window-height);
  position: relative;
`;

const Name = styled.h1<{ delay: number }>`
  ${fadeDownInCss};
  animation-delay: ${props => props.delay}ms;
  backdrop-filter: blur(12px);
  border-radius: 8px;
  bottom: 48px;
  color: white;
  font-size: 4.5rem;
  font-weight: lighter;
  left: 16px;
  letter-spacing: -1px;
  line-height: 0.8;
  margin: 0;
  padding: 12px 16px;
  position: absolute;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    left: unset;
    right: 20%;
    font-size: 6.5rem;
    bottom: 20%;
  }
`;

const Line = styled.div<{ delay: number }>`
  ${fadeDownInCss};
  animation-delay: ${props => props.delay}ms;
`;

const Bio = styled.div`
  position: absolute;
  top: 72px;
  left: 24px;
  color: white;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: lighter;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  @media (min-width: 768px) {
    right: 20%;
    left: unset;
    top: 10%;
    font-size: 4rem;
    text-align: right;
  }
`;

const BackgroundImg = styled.div<{
  delay: number;
  img: string;
  desktopImg: string;
}>`
  background-color: #222;
  background-image: url(${props => props.img});
  background-position: left center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (min-width: 768px) {
    background-image: url(${props => props.desktopImg});
  }
`;

export const TopPanel = (props: { img: string; desktopImg: string }) => (
  <Panel>
    <BackgroundImg img={props.img} delay={50} desktopImg={props.desktopImg} />
    <Bio>
      <Line delay={350}>practical mystical fool</Line>
      <Line delay={950}>lifting the veil</Line>
      <Line delay={1950}>
        Love{' '}
        <Line as="span" delay={2500}>
          is a verb
        </Line>
      </Line>
    </Bio>
    <Name delay={3000}>nettie lane</Name>
  </Panel>
);
