import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { TopPanel } from 'components/home/top_panel';
import Meta from 'components/meta';

const Homepage = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      headerImage: imageSharp(
        original: { src: { regex: "/mobile_home_page_background/" } }
      ) {
        fluid(maxWidth: 1600) {
          src
        }
      }
      desktopHeaderImage: imageSharp(
        original: { src: { regex: "/desktop_home_page_background/" } }
      ) {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
  `);
  return (
    <>
      <Meta image={data.headerImage.fluid.src} />
      <TopPanel
        img={data.headerImage.fluid.src}
        desktopImg={data.desktopHeaderImage.fluid.src}
      />
    </>
  );
};

export default Homepage;
